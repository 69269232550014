import Link from '../../../components/common/Link';
import SaleBadge from '../../../components/common/SaleBadge';
import { generateSrcSet } from '../../../utils/generateSrcSet';
import { roundPrice } from '../../../utils/roundPrice';

import Image from '../../common/ui/Image';

import type { SearchResultCardProps } from '../../../types';

const SearchResultCard = ({
  cardRoute,
  cardImage,
  cardTitle,
  currentPrice,
  pastPrice,
  onClick,
  badge = '',
}: SearchResultCardProps) => {
  const roundedCurrentPrice = roundPrice(currentPrice);
  const roundedPastPrice =
    typeof pastPrice === 'number' && pastPrice > 0 ? roundPrice(pastPrice) : null;
  const displayReduction = pastPrice !== currentPrice;

  return (
    <Link
      className="group flex no-underline lg:flex-col"
      href={cardRoute}
      onClick={() => onClick?.(cardTitle)}
    >
      <div className="relative mr-3 inline-flex h-20 w-20 shrink-0 overflow-hidden rounded lg:mb-auto lg:mr-0 lg:aspect-square lg:h-auto lg:max-h-[188px] lg:w-auto">
        <Image
          src={cardImage}
          srcSet={generateSrcSet(cardImage, [
            { width: 128, breakpoint: '640w' },
            { width: 256, breakpoint: '992w' },
            { width: 384, breakpoint: '1450w' },
            { width: 640, breakpoint: '2560w' },
          ])}
          alt={cardTitle}
          fill
          objectFit="cover"
        />
        {badge && <SaleBadge className="absolute left-0 top-0">{badge}</SaleBadge>}
      </div>
      <div className="flex flex-col">
        <h5 className="mb-4 mt-2 text-sm font-semibold leading-normal text-neutral-strong group-hover:text-primary-500">
          {cardTitle}
        </h5>
        <div className="flex items-baseline">
          <div className="font-semibold leading-none text-neutral-strong">
            {roundedCurrentPrice}
          </div>
          {displayReduction && (
            <div className="ml-1 text-sm leading-none text-neutral-faded line-through">
              {roundedPastPrice}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default SearchResultCard;
