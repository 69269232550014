// TODO LP_5659: apply logic to extend the promotion functionality for Valentines and Mother's Day
// Priority: Post-release

import algoliasearch from 'algoliasearch';
import { useState, useEffect } from 'react';

import { PromoService, vedPromosConfig } from '../promos';
import { getLocationInfo } from '../utils/productInfo';

import useDebounce from './useDebounce';

import type { EnvVariables, SearchResultCardProps, SuggestionsProps } from '../types';

const UID_BLACK_FRIDAY = 'black-friday';
const UID_CHRISTMAS = 'christmas-deals';
const UID_MOTHERS_DAY = 'mothers-day-special-offers';
const UID_VALENTINES = 'valentines-special-offers';
const UID_EASTER = 'easter-gifts-for-kids';
const UID_FATHERS_DAY = 'fathers-day-special-offers';

export type AlgoliaEnvVars = {
  algoliaAppId: EnvVariables['algoliaAppId'];
  algoliaSearchApiKey: EnvVariables['algoliaSearchApiKey'];
  algoliaSearchIndex: EnvVariables['algoliaSearchIndex'];
  algoliaSuggestionIndex: EnvVariables['algoliaSuggestionIndex'];
};

const useAlgolia = (
  envVariables: AlgoliaEnvVars,
  searchTerm = '',
  debounce = 200,
  ignoreChars = 0
) => {
  const searchClient = algoliasearch(envVariables.algoliaAppId, envVariables.algoliaSearchApiKey);

  const [algoliaProducts, setAlgoliaProducts] = useState<SearchResultCardProps[]>([]);
  const [algoliaSuggestions, setAlgoliaSuggestions] = useState<SuggestionsProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const debounced = useDebounce(searchTerm, debounce);

  useEffect(() => {
    if (debounced.length <= ignoreChars) {
      return;
    }

    const getAlgoliaCards = async () => {
      try {
        setLoading(true);
        const results = await getResults({
          client: searchClient,
          query: debounced,
          searchIndex: envVariables.algoliaSearchIndex,
          suggestionIndex: envVariables?.algoliaSuggestionIndex,
        });

        setAlgoliaProducts(results?.products);
        setAlgoliaSuggestions(results?.suggestions);
      } catch (error) {
        // @TO DO error handling
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getAlgoliaCards();
  }, [debounced]);

  return { algoliaProducts, algoliaSuggestions, loading, debounced };
};

export default useAlgolia;

async function getResults({
  client,
  query,
  searchIndex,
  suggestionIndex,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;
  query: string;
  searchIndex: string;
  suggestionIndex: string;
}) {
  const results = await client
    .multipleQueries(createQueries({ query, searchIndex, suggestionIndex }))
    .then((response: Response) => mapAlgoliaResponse(response));
  return results;
}

const createQueries = ({
  query,
  searchIndex,
  suggestionIndex,
}: {
  query: string;
  searchIndex: string;
  suggestionIndex: string;
}) => [
  {
    indexName: searchIndex,
    query,
    params: {
      hitsPerPage: 4,
    },
  },
  {
    indexName: suggestionIndex,
    query,
    params: {
      hitsPerPage: 4,
    },
  },
];

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks if a product has the "black-friday" feature facet.
 * @param product - The product to check.
 * @returns True if the product has the "black-friday" feature facet, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasBlackFridayFacet = (product: Record<string, any>): boolean | null => {
  const features = product?.facets?.features;
  const featureHierarchies = product?.facets?.featureHierarchies?.lvl0;

  const onFeatures = Array.isArray(features) && features.includes(UID_BLACK_FRIDAY);
  const onFeatureHierarchies =
    Array.isArray(featureHierarchies) && featureHierarchies.includes(UID_BLACK_FRIDAY);

  return (onFeatures || onFeatureHierarchies) ?? false;
};

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks if a product has the "christmas-deals" feature facet.
 * @param product - The product to check.
 * @returns True if the product has the "christmas-deals" feature facet, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasChristmasFacet = (product: Record<string, any>): boolean | null => {
  const features = product?.facets?.features;
  const featureHierarchies = product?.facets?.featureHierarchies?.lvl0;

  const onFeatures = Array.isArray(features) && features.includes(UID_CHRISTMAS);
  const onFeatureHierarchies =
    Array.isArray(featureHierarchies) && featureHierarchies.includes(UID_CHRISTMAS);

  return (onFeatures || onFeatureHierarchies) ?? false;
};

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks if a product has the "valentines-special-offers" occasion facet.
 * @param product - The product to check.
 * @returns True if the product has the facet, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasValentinesFacet = (product: Record<string, any>): boolean | null => {
  const occasions = product?.facets?.occasions;
  return Array.isArray(occasions) && occasions.includes(UID_VALENTINES);
};

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks if a product has the "mothers-day-special-offers" occasion facet.
 * @param product - The product to check.
 * @returns True if the product has the facet, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasMothersDayFacet = (product: Record<string, any>): boolean | null => {
  const occasions = product?.facets?.occasions;
  return Array.isArray(occasions) && occasions.includes(UID_MOTHERS_DAY);
};

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks if a product has the "fathers-day-special-offers" occasion facet.
 * @param product - The product to check.
 * @returns True if the product has the facet, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasFathersDayFacet = (product: Record<string, any>): boolean | null => {
  const occasions = product?.facets?.occasions;
  return Array.isArray(occasions) && occasions.includes(UID_FATHERS_DAY);
};

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks if a product has the easter occasion facet.
 * @param product - The product to check.
 * @returns True if the product has the facet, false otherwise.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const hasEasterFacet = (product: Record<string, any>): boolean | null => {
  const occasions = product?.facets?.occasions;
  return Array.isArray(occasions) && occasions.includes(UID_EASTER);
};

/**
 * NOTE: sync with @virginexperiencedays/products/src/util
 *
 * Checks whether a product has easter facet if NEXT_PUBLIC_FF_EASTER is enabled
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isEnabledEaster = (product: Record<string, any>, ffEnabled?: string): boolean => {
  if (!ffEnabled) return false;
  if (parseInt(ffEnabled) !== 1) return false;
  return !!hasEasterFacet(product);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function mapAlgoliaResponse(response: Record<string, any>) {
  const productUrl = (slug: string, isCollectionProduct: boolean) =>
    isCollectionProduct ? `/collection/${slug}` : `/product/${slug}`;

  const promoService = new PromoService({
    activePromos: process.env.NEXT_PUBLIC_ACTIVE_PROMOS as string,
    promosConfiguration: vedPromosConfig,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const products: SearchResultCardProps[] = response?.results?.[0]?.hits?.map((hit: any) => ({
    id: hit?.id,
    cardRoute: productUrl(hit?.slug, hit?.isCollectionProduct),
    // used by variation A (old product card)
    cardImage: hit?.media?.mainImage?.url,
    // used by variation B (product card 2024)
    cardImages: [hit?.media?.mainImage, ...(hit?.media?.images || [])].map((img) => ({
      src: img?.url || '',
      alt: img?.alt || hit?.title,
    })),
    cardFullSku: hit?.promocode ? `${hit?.sku} ${hit?.promocode}` : hit?.sku,
    cardTitle: hit?.title,
    categories: hit?.categories || [], // categories for location text override for product card 2024
    reviews: hit?.reviews,
    locations: getLocationInfo(hit),
    currentPrice: hit?.price?.displayPrice,
    pastPrice: hit?.price?.rrp,
    percentOff: hit?.price?.percentOff,
    productPromo: promoService.getProductPromo(hit),
    isCollectionProduct: hit?.isCollectionProduct,
  }));

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suggestions: SuggestionsProps[] = response?.results?.[1]?.hits?.map((hit: any) => ({
    name: hit?.query,
    route: `search?query=${hit?.query}`,
  }));
  return { products, suggestions };
}
