export const fetchVPApi = async (
  url: string,
  query: string,
  variables?: Record<string, string[] | string>
): Promise<ProductResponse> => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // https://www.apollographql.com/docs/studio/metrics/client-awareness/
      'apollographql-client-name': 'web-frontend-server',
      'apollographql-client-version': '1',
    },
    body: JSON.stringify({ query, variables }),
  };

  const data = await fetch(url, options);

  if (data?.status !== 200) {
    throw new Error(`Product API request failed: ${data?.status} - ${data?.statusText}`);
  }
  const json = await data.json();
  return json.data;
};

export type ProductResponse = {
  products?: Product[];
};

export type Product = {
  readonly id: number;
  readonly title: string;
  readonly slug: string;
  readonly isCollectionProduct: boolean;
  readonly url: string;
  readonly media: {
    mainImage: { url: string; alt: string };
    images: { url: string; alt: string }[];
  };
  readonly price: { rrp: number; displayPrice: number; percentOff: number };
  readonly status: string;
  readonly sku: string;
  readonly promocode: string;
  readonly locations: { name: string }[];
  readonly reviews: { averageRating: number; count: number };
  readonly dateActivated: number;
  readonly isExclusive: boolean;
};

const productCardQuery = `#graphql
    id
    title
    sku
    promocode
    url
    media {
      mainImage {
      url
      }
    }
    price {
        rrp
        displayPrice
    }
    status
    slug
    isCollectionProduct
    locations {
      name
    } 
    reviews {
      averageRating
      count
    }
    dateActivated
    isExclusive
`;

export const VIEWED_PRODUCTS = `#graphql
query GetProductCards($productIds: Ids) {
  products(productIds: $productIds) {
    ${productCardQuery}
  }
}
`;
