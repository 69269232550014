import { useEffect, useRef, useState } from 'react';

import { useHeaderContext } from '../../context/HeaderContext';
import { ThemeProvider } from '../../context/ThemeContext';
import { useScrollLock, useViewportSize } from '../../hooks';
import {
  cn,
  ctaLinkToDataLayer,
  logoToDataLayer,
  searchOpenToDataLayer,
  tailwindBreakpoints,
} from '../../utils';
import BlurScreen from '../common/BlurScreen';
import Link from '../common/Link';
import ButtonLink from '../common/ui/buttons/ButtonLink';
import SearchBarButton from '../common/ui/buttons/SearchBarButton';
import Divider from '../common/ui/Divider';
import Logo from '../common/ui/Logo';
import PromoBanners from '../MegaNav/Desktop/PromoBanners';

import HeaderButtonGroup from './ButtonGroup';
import HeaderMenu from './HeaderMenu';
import SearchOverlay from './SearchOverlay';

import type { HeaderProps } from '../../types';

const Header = ({ navigation, theme, immersive }: HeaderProps) => {
  const { siteLogoDefault, siteLogoWhite, searchState, handleUpdateSearchState } =
    useHeaderContext();
  const { hideBasketCount, basketCount, saveForLaterCount } = useHeaderContext();
  const viewportWidth = useViewportSize();

  const mobileNavRef = useRef<HTMLElement>(null);
  const mobileBlurRef = useRef<HTMLDivElement>(null);

  const [navExpanded, setNavExpanded] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const mobileSearchOpen = viewportWidth < tailwindBreakpoints.lg && searchState.searchOverlay;

  useScrollLock(mobileNavOpen || mobileSearchOpen);

  // close mobile navigation when viewport width changes to desktop and vice versa
  useEffect(() => {
    if (viewportWidth > tailwindBreakpoints.lg) setMobileNavOpen(false);
    else setNavExpanded(false);
  }, [viewportWidth]);

  // desktop navigation handler
  const handleNavExpanded = (expanded: boolean) => {
    setNavExpanded(expanded);
  };

  // mobile navigation handler
  // (open -> add class -> remove class -> close)
  const handleToggleMobileNav = async () => {
    // to close: wait for transition class to be removed first, then unmount component via state change
    if (mobileNavOpen) {
      mobileNavRef?.current?.classList?.remove('translate-x-0');
      mobileBlurRef?.current?.classList?.remove('opacity-100');
      const delay = (ms: number) => new Promise((r) => setTimeout(r, ms));
      await delay(200);
    }

    setMobileNavOpen((currentValue) => {
      const newValue = !currentValue;

      // to open: wait for component to be mounted via state change, then add transition class
      if (newValue) {
        // scroll to top of page when opening mobile nav
        // this ensures the meganav is always below the search bar, since search bar is not sticky nor fixed
        window?.scrollTo({ behavior: 'smooth', top: 0 });

        setTimeout(() => {
          mobileNavRef?.current?.classList?.add('translate-x-0');
          mobileBlurRef?.current?.classList?.add('opacity-100');
          clearTimeout(this);
        }, 5);
      }

      return newValue;
    });
  };

  // search component handler
  const handleToggleSearchOpen = () => {
    searchOpenToDataLayer();
    handleUpdateSearchState({ searchOverlay: true });
  };

  // close mobile navigation and search overlay when clicking outside
  const handleClickOutside = () => {
    setMobileNavOpen(false);
    handleUpdateSearchState({ searchOverlay: false });
  };

  // close navigations and search overlay e.g. clicking logo, popular products, recently viewed
  const handleCloseNavAndSearch = () => {
    setNavExpanded(false);
    mobileNavOpen && handleToggleMobileNav();
    handleUpdateSearchState({ searchOverlay: false });
  };

  const showMobileBlur = mobileNavOpen || searchState.searchOverlay;
  const showDesktopBlur = navExpanded || searchState.searchOverlay;

  const myVoucherLink = navigation?.links?.myVoucher;

  return (
    <ThemeProvider theme={theme} immersive={immersive}>
      {showDesktopBlur && (
        <BlurScreen className={cn(style.blurScreen._, style.blurScreen.desktop)} />
      )}
      <header
        className={cn(
          style.header._,
          { [style.header.immersive]: immersive },
          { ['border-background-neutral-strong']: immersive && theme !== 'dark' }
        )}
      >
        {showMobileBlur && (
          <BlurScreen
            ref={mobileBlurRef}
            className={cn(style.blurScreen._, style.blurScreen.mobile)}
            onClick={handleClickOutside}
          />
        )}
        <PromoBanners promoBanners={navigation?.promoBanner} />
        <div className={cn(style.container._, { [style.container.immersive]: immersive })}>
          <Link
            href="/"
            onClick={() => {
              logoToDataLayer();
              handleCloseNavAndSearch();
            }}
            className={cn(
              'm-4 flex h-10 w-[100px] items-center justify-center overflow-hidden lg:m-0',
              style.gridArea.logo
            )}
          >
            <Logo src={{ red: siteLogoDefault as string, white: siteLogoWhite as string }} />
          </Link>

          <SearchBarButton
            className={style.gridArea.search}
            placeholder="Search experiences..."
            onClick={handleToggleSearchOpen}
          />

          {!!myVoucherLink && (
            <ButtonLink
              href={myVoucherLink}
              onClick={() => ctaLinkToDataLayer('Got a voucher?', myVoucherLink)}
              className={cn('text-md hidden py-2 lg:flex lg:h-10', {
                'border-persistent-border-disabled text-persistent-text-light': immersive,
              })}
            >
              Got a voucher?
            </ButtonLink>
          )}
          <HeaderButtonGroup
            className={cn(style.gridArea.buttons)}
            basketCount={basketCount}
            hideBasketCount={hideBasketCount}
            saveForLaterCount={saveForLaterCount}
            links={navigation?.links}
          />
          {!!navigation?.navItems?.length && (
            <HeaderMenu
              className={cn('lg:pt-1', style.gridArea.menu)}
              navigation={navigation}
              navExpanded={navExpanded}
              mobileNavOpen={mobileNavOpen}
              handleNavExpanded={handleNavExpanded}
              handleToggleMobileNav={handleToggleMobileNav}
              mobileNavRef={mobileNavRef}
            />
          )}
          <Divider className={cn('lg:my-0', style.gridArea.divider)} />
        </div>

        {searchState.searchOverlay && (
          <SearchOverlay
            logoSrc={{ red: siteLogoDefault, white: siteLogoWhite }}
            handleLinkClicks={handleCloseNavAndSearch}
          />
        )}
      </header>
    </ThemeProvider>
  );
};

export default Header;

const style = {
  header: {
    _: 'h-[120px] [&_*]:font-inter lg:h-auto relative w-full isolate flex flex-col bg-background-page z-40',
    immersive: 'absolute top-0 left-0 bg-transparent',
  },
  container: {
    _: 'relative mx-auto grid items-center w-full max-w-[1440px] grid-cols-[58px,auto,auto] lg:grid-cols-[auto,1fr,auto,auto] lg:gap-x-2 lg:gap-y-4 lg:px-[40px] lg:pt-6 xl:px-[80px]',
    immersive: 'lg:border-persistent-border-translucent-light',
  },
  gridArea: {
    logo: 'col-start-1 col-span-2 lg:col-start-1 lg:col-span-1',
    cta: 'lg:col-start-3 lg:col-span-1',
    buttons: 'h-10 col-start-3 col-span-1 lg:col-start-4 lg:col-span-1',
    search:
      'row-start-2 col-start-2 col-span-2 lg:row-start-1 lg:col-start-2 lg:col-span-1 lg:mx-4',
    menu: 'col-start-1 col-span-1 lg:col-start-1 lg:col-span-4',
    divider: 'hidden lg:block col-start-1 col-span-4',
  },
  blurScreen: {
    _: 'z-40',
    mobile: 'opacity-0 transition-fade duration-150 lg:hidden',
    desktop: 'hidden lg:block',
  },
};
