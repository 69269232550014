import type { ProductPromo } from '../promos';
import type { SearchResultCardProps } from '../types';

const DTL = 30;

type ProductTuple = [string, ViewedProductItem];

export function getViewedProducts(
  dateTime: number,
  storage: ProductTuple[],
  maxItems = 4
): SearchResultCardProps[] {
  if (storage?.length) {
    // filter expired entries
    // sort in order of most recent
    // return set number of entries
    const arranged = storage
      // NOTE: may not need this in the future, because of cookie's own max age
      .filter((product: ProductTuple) => product[1].added + DTL * 24 * 60 * 60 * 1000 > dateTime)
      .sort((a, b) => b[1].added - a[1].added)
      .slice(0, maxItems);

    // extract product cards from storage entries
    return arranged.map((x) => x[1].vpCard as SearchResultCardProps);
  }
  return [];
}

export type ViewedProductCard = {
  id: string | number;
  cardRoute: string;
  cardImage: string;
  cardImages?: { src: string; alt: string }[];
  cardFullSku?: string;
  cardTitle: string;
  currentPrice: number;
  pastPrice?: number;
  percentOff?: number;
  locations?: number | string;
  reviews?: { averageRating: number; count: number };
  badge?: string;
  dateActivated?: number;
  isExclusive?: boolean;
  productPromo: ProductPromo | null;
};

export type ViewedProductItem = {
  vpCard?: ViewedProductCard;
  added: number;
  lastValidated?: number;
};

export type ViewedProductMeta = {
  id: string;
  added: number;
};
