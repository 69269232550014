import { useHeaderContext } from '../../../context/HeaderContext';
import { cn } from '../../../utils';
import { generateRoute } from '../../../utils/generateRoute';
import { useIsProductCardsV2 } from '../../common/ui/ProductV2';
import { ProductCardRenderer as ProductCard } from '../../common/ui/ProductV2/ProductCardRenderer';

import ClearSearchHistoryButton from './ClearSearchHistoryButton';
import SearchSectionTitle from './SearchSectionTitle';

import type { SearchResultCardProps } from '../../../types';

export type SearchResultCardsProps = {
  searchTerm: string;
  cards: SearchResultCardProps[];
  showSeeAll: boolean;
  showClearHistory: boolean;
  resultsTitle: string;
  noResultsText: string;
  onClearClick: () => void;
  onCardClick: (title: string) => void;
};

const SearchResultCards = ({
  searchTerm,
  cards,
  showSeeAll = false,
  showClearHistory = false,
  resultsTitle,
  noResultsText,
  onClearClick,
  onCardClick,
  ...rest
}: SearchResultCardsProps) => {
  const { homeLink, NextLink } = useHeaderContext();
  const isProductCardsV2 = useIsProductCardsV2();

  const seeAllResults = (searchTerm: string) => {
    const initialRoute = generateRoute('search', { isNextLink: !!NextLink, homeLink });
    const searchUrl = searchTerm ? `${initialRoute}?query=${searchTerm}` : initialRoute;
    window.location.href = searchUrl;
  };
  return (
    <div className="grow" {...rest}>
      <SearchSectionTitle>
        {resultsTitle ? <span>{resultsTitle}</span> : null}
        {showClearHistory && (
          <ClearSearchHistoryButton onClick={onClearClick} className="hidden lg:block" />
        )}
      </SearchSectionTitle>
      <ul
        className={cn(
          'm-0 flex list-none flex-col gap-4 p-0 lg:flex-row xl:flex-row',
          isProductCardsV2 && cards.length > 0 && 'grid grid-cols-2 lg:flex'
        )}
      >
        {cards.length > 0 ? (
          cards.map((props: SearchResultCardProps, index: number) => {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const item = { ...props, onClick: onCardClick } as any;

            return (
              <li
                key={`searchCardItem-${props.id}`}
                className="lg:w-[calc(100%/4)] [&:nth-child(4)]:lg:hidden [&:nth-child(4)]:xl:block [&:nth-child(n+5)]:xl:hidden"
              >
                <ProductCard
                  titleClassName={isProductCardsV2 ? 'line-clamp-3' : ''}
                  isSearchCard
                  isImageCarousel
                  item={item}
                  index={index}
                  tracking={{
                    module:
                      resultsTitle?.toLowerCase() === 'recently viewed'
                        ? 'header_recently_viewed'
                        : 'header_search',
                  }}
                />
              </li>
            );
          })
        ) : (
          <p className="m-0 text-sm leading-normal lg:font-normal lg:leading-tight">
            {noResultsText}
          </p>
        )}
      </ul>
      {showSeeAll && (
        <button
          className="mt-8 block h-10 w-full cursor-pointer rounded border-none bg-background-primary text-center text-sm font-semibold leading-none text-white hover:bg-background-primary-highlight focus:bg-background-primary lg:ml-auto lg:mr-auto lg:mt-6 lg:w-1/2"
          onClick={() => seeAllResults(searchTerm)}
        >
          See all results
        </button>
      )}

      {showClearHistory && (
        <ClearSearchHistoryButton onClick={onClearClick} className="block lg:hidden" />
      )}
    </div>
  );
};

export default SearchResultCards;
