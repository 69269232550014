import { type ReactNode, createContext, useContext, useState, useMemo } from 'react';

import { usePageShow } from '../../hooks';
import useBasketCount from '../../hooks/useBasketCount';

import type { HeaderProps, EnvVariables } from '../../types';

type Context = {
  homeLink: EnvVariables['homeLink'];
  NextLink: HeaderProps['NextLink'];
  NextImage: HeaderProps['NextImage'];
  NextRouter: HeaderProps['NextRouter'];
  siteLogoDefault: EnvVariables['siteLogoDefault'];
  siteLogoWhite: EnvVariables['siteLogoWhite'];
  basketCount: number;
  hideBasketCount: boolean;
  saveForLaterCount: number;
  searchState: SearchStateType;
  trackingPageType?: string;
  handleToggleBasketCount: () => void;
  handleToggleSearchOpen: () => void;
  handleResetSearchState: () => void;
  handleUpdateBasketCount: (count: number) => void;
  handleUpdateSaveForLaterCount: (count: number) => void;
  handleUpdateSearchState: (value: SearchStateType) => void;
} & EnvVariables;

export type SearchStateType = {
  searchOverlay?: boolean;
  searchTerm?: string;
};

const defaultState: Context = {
  homeLink: '',
  NextLink: null,
  NextImage: null,
  NextRouter: null,
  algoliaAppId: '',
  algoliaSearchApiKey: '',
  algoliaSearchIndex: '',
  algoliaSuggestionIndex: '',
  prismicRepoEndpoint: '',
  prismicToken: '',
  productApiUrl: '',
  cookieDomain: '',
  isProductCardsV2: '',
  basketCount: 0,
  hideBasketCount: false,
  saveForLaterCount: 0,
  siteLogoDefault: '',
  siteLogoWhite: '',
  trackingPageType: '',
  handleUpdateBasketCount: () => null,
  handleToggleBasketCount: () => null,
  handleUpdateSaveForLaterCount: () => null,
  searchState: {
    searchOverlay: false,
    searchTerm: '',
  },
  handleToggleSearchOpen: () => null,
  handleUpdateSearchState: () => null,
  handleResetSearchState: () => null,
};
const HeaderContext = createContext<Context>({
  ...defaultState,
});

type Props = {
  NextLink?: HeaderProps['NextLink'];
  NextImage?: HeaderProps['NextImage'];
  NextRouter?: HeaderProps['NextRouter'];
  hideBasketCount?: boolean;
  trackingPageType?: string;
  children: ReactNode;
} & EnvVariables;

export const HeaderProvider = ({
  children,
  NextLink,
  NextImage,
  NextRouter,
  hideBasketCount: _hideBasketCount = false,
  trackingPageType,
  ...envVariables
}: Props) => {
  const [hideBasketCount, setHideBasketCount] = useState(_hideBasketCount);
  const [saveForLaterCount, setSaveForLaterCount] = useState(0);
  const [searchState, setSearchState] = useState<typeof defaultState.searchState>(
    defaultState.searchState
  );

  const { basketCount, handleSetBasketCount } = useBasketCount(envVariables.homeLink);

  // ? reset search state on page show to prevent search term from persisting in iOS
  usePageShow((e: PageTransitionEvent) => {
    if (e.persisted) setSearchState(defaultState.searchState);
  });

  const handleUpdateBasketCount = (count: number) => {
    handleSetBasketCount(count);
  };

  const handleToggleBasketCount = () => {
    setHideBasketCount((prev) => !prev);
  };

  const handleUpdateSaveForLaterCount = (count: number) => {
    setSaveForLaterCount(count);
  };

  const handleToggleSearchOpen = () => {
    setSearchState((prev) => ({
      ...prev,
      searchOverlay: !prev.searchOverlay,
      searchTerm: '',
    }));
  };

  const handleUpdateSearchState = (value: SearchStateType) => {
    setSearchState((prev) => ({
      ...prev,
      ...value,
    }));
  };

  const handleResetSearchState = () => {
    setSearchState(defaultState.searchState);
  };

  const value = useMemo(
    () => ({
      NextLink,
      NextImage,
      NextRouter,
      basketCount,
      hideBasketCount,
      saveForLaterCount,
      searchState,
      trackingPageType,
      handleResetSearchState,
      handleToggleSearchOpen,
      handleUpdateBasketCount,
      handleToggleBasketCount,
      handleUpdateSaveForLaterCount,
      handleUpdateSearchState,
      ...envVariables,
    }),
    [basketCount, hideBasketCount, saveForLaterCount, searchState, trackingPageType]
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

export const useHeaderContext = () => useContext(HeaderContext);
